var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shopping-bag__item-price",class:_vm.priceClassName,style:({ opacity: _vm.hidePrice ? 0 : 1 })},[(!_vm.isClient)?_c('span',{staticClass:"goods-price",class:`j-goods-price__${_vm.item.id}`},[_vm._v("\n    "+_vm._s(_vm.getPriceByAbt)+"\n  ")]):_c('div',{staticClass:"goods-price",style:({ maxWidth: '74px'})},[_c('SAdapterText',{class:`j-goods-price__${_vm.item.id}`,attrs:{"min-size":"10","text":_vm.getPriceByAbt}})],1),_vm._v(" "),(!_vm.isSuggested && _vm.item.product.unit_discount > 0)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isHideDiscount),expression:"!isHideDiscount"}],staticClass:"goods-discount j-goods-discount",class:[
      `j-goods-discount__${_vm.item.id}`,
      {
        'goods-discount-prime': _vm.isPremiumMember,
        'goods-discount-v2': _vm.discountLogoByAbt,
        'club-gift-discount': _vm.isSheinClubGift
      }
    ],attrs:{"aria-hidden":"true"}},[_c('span',[_vm._v("-"+_vm._s(_vm.item.product.unit_discount)+"%")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }