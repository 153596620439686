<template>
  <div class="shopping-bag-options__item-wrap">
    <div>
      <li
        :class="['bag-item-effiency']"
      >
        <img 
          v-if="fromType == 'shoppingMall'"
          class="i-img" 
          :class="{'fsp-element': index == 0}"
          :fsp-key="index == 0 ? `checkout_ShoppingBagItemV2_1_${baseCutImg}` : null"
          :src="baseCutImg" 
          fetchpriority="high"
        />
        <img
          v-else
          class="i-img"
          :src="transformImg({img: item.product.goods_img})"
        />
        <img
          v-if="isSensitiveGood"
          class="product-sensitive"
          :src="`${PUBLIC_CDN}/pwa_dist/images/checkout/sui_icon_sensitive-f1bf4c1570.svg`"
        />
        <div 
          v-if="goodsStatus"
          class="shopping-bag-options__item-tips"
          :class="{
            'add-tips': isAddBuyGoods,
            'gift-tips': isGiftGoods,
            'soldout-tips': isOutStockGood,
            'club-gift-tips': isSheinClubGift
          }"
        >
          {{ goodsStatus }}
        </div>
      </li>

      <Price 
        class="shopping-bag-options__item-price"
        :item="item"
        :is-shein-club-gift="isSheinClubGift"
      />

      <div 
        v-if="chemicalsIds.includes(item.product.goods_sn)" 
        class="shopping-bag-options__item-warning" 
        @click.stop="showP65Warning(item.product.goods_sn)" 
      >
        <img :src="IMG_LINK['warning']" />
        <span>{{ language.SHEIN_KEY_PWA_20813 }}</span>
      </div>
    </div>

    <InputNumber 
      class="shopping-bag-options__item-number" 
      :item="item"
      :isLastGoods="isLastGoods"
      :isPromitionGoods="isPromitionGoods(item)"
      :isOutStockGood="isOutStockGood"
      source="outer"
      :isSheinClubGiftItem="isSheinClubGift"
      @click.native.stop
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { transformImg } from '@shein/common-function'

import Price from './ShoppingBagItemPrice.vue'
import InputNumber from 'public/src/pages/checkout/vue_tpls/shopping_bag/InputNumber.vue'

import { isPromitionGoods } from 'public/src/pages/checkout/config/tools.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'

const { IMG_LINK, PUBLIC_CDN } = gbCommonInfo

export default {
  name: 'ShoppingBagItem',
  components: {
    Price,
    InputNumber,
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    isSheinClubGift: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    isOutStockGood: {
      type: Boolean,
      default: false
    },
    fromType: {
      type: String,
      default: ''
    },
    isSensitiveGood: {
      type: Boolean,
      default: false
    },
    isLastGoods: {
      type: Boolean,
      default: false
    },
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  data() {
    return {
      IMG_LINK,
      PUBLIC_CDN,
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'chemicalsIds',
    ]),
    ...mapState('checkout', [
      'language', 
      'checkout', 
      'locals', 
    ]),
    baseCutImg() {
      if (!this?.cutImg) {
        return this.item.product.goods_img.replace(/^https?\:/, '').replace(/\b(\.jpg|\.gif|\.png)\b/, '.webp')
      }
      return this.cutImg(this.item.product.goods_img, 0, {})
    },
    isAddBuyGoods() {
      return this.item?.product?.product_promotion_info?.some(promo => [2].includes(+promo.promotion_product_type)) ? true : false
    },
    isGiftGoods() {
      const cartGift = this.item?.product?.product_promotion_info?.some(promo => [1].includes(+promo.promotion_product_type)) ? true : false
      const checkoutGift = this.item?.promotion_type_id == '1000'

      return cartGift || checkoutGift
    },
    goodsStatus() {
      if(this.isOutStockGood) {
        return this.language.SHEIN_KEY_PWA_14987
      } else if(this.isGiftGoods) {
        return this.language.SHEIN_KEY_PWA_25536
      } else if(this.isSheinClubGift) {
        return this.language.SHEIN_KEY_PWA_31169
      } else if(this.isAddBuyGoods) {
        return this.language.SHEIN_KEY_PWA_28687
      }
      return ''
    },
  },
  methods: {
    ...mapMutations('checkout', ['assignState']),
    transformImg,
    isPromitionGoods,
    async showP65Warning (goods_sn) {
      let caculate_info = this.checkout?.mall_caculate_info || {}
      let { defaultP65TipsContent, chemicalsIdsEvt } = await import('public/src/pages/checkout/tools/p65util.js')
      let p65Text = await chemicalsIdsEvt(caculate_info, goods_sn)
      this.assignState({
        p65TipsShowText: p65Text || defaultP65TipsContent(this.language),
        p65TipsShow: true
      })
      sa('send', { activity_name: 'click_p65warning' })
      daEventCenter.triggerNotice({
        daId: '1-11-1-238',
        extraData: {
          sku_code: goods_sn
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.shopping-bag-options__item-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .margin-r(8/75rem);
  &:last-child {
    .margin-r(24/75rem);
  }
}
.bag-item-effiency {
  position: relative;
  border-bottom: none;
  padding: 0;
  margin-bottom: 0;

  >img{
    width: 148/75rem;
    height: 148/75rem;
    object-fit: cover;
  }
  .product-sensitive {
    width: 32/75rem;
    height: 32/75rem;
    position: absolute;
    top: 8/75rem;
    right: 8/75rem;
  }
  .shopping-bag-options__item-tips {
    position: absolute;
    bottom: 0;
    .left(0);
    width: 100%;
    .font-dpr(20px);
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.add-tips {
      background: #FCF5EF;
      color: #AD633D;
    }
    &.gift-tips {
      background: #FB7751;
      color: #fff;
    }
    &.soldout-tips {
      background: @sui_color_black;
      opacity: 0.6;
      color: #fff;
    }
    &.club-gift-tips {
      color: @sui_color_white;
      background-color: @sui_color_club_rosegold_dark1;
    }
  }
}
.shopping-bag-options__item-price {
  margin-top: 8/75rem;
}
.shopping-bag-options__item-warning {
  line-height: 1;
  margin-top: 8/75rem;
	width: 148/75rem;
  border: 1px solid #D39A8E;
  background: #FFEEEB;
  padding: 3/75rem 8/75rem;
	img {
		height: 20/75rem;
		width: auto;
    .margin-r(4/75rem);
	}
	span {
    color: @color_red_warning;
    text-transform: uppercase;
    .font-dpr(20px);
    font-weight: 700;
	}
}
.shopping-bag-options__item-number {
  margin-top: 8/75rem;
}
</style>
